/*------------------------------------------------------------------
    1. DEFAULT COLOR
-------------------------------------------------------------------*/

#navbar_menu ul ul li a:hover,
#navbar_menu ul ul li a:focus,
.bg_load,
.theme_color_bg,
.price_table_active .price_head,
a.btn.dark_gray_bt:hover,
a.btn.dark_gray_bt:focus,
.carousel-indicators .active,
.social_icon_style_2 ul li a:hover,
.social_icon_style_2 ul li a:focus,
.progress-bar,
.tags a:hover,
.tags a:focus,
.testimonial_slider .carousel-control:hover,
.testimonial_slider .carousel-control:focus,
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover,
.icon_feature,
a.btn.main_bt,
button.btn.main_bt {
	background-color: #e1192f;
}

#navbar_menu ul ul {
	border-color: #e1192f;
}

#navbar_menu.small-screen #menu-button {
	border-color: #4f4e50;
}

#navbar_menu.small-screen #menu-button::before,
#navbar_menu.small-screen #menu-button.menu-opened::after,
#navbar_menu.small-screen #menu-button.menu-opened::before {
	background: #4f4e50;
}

#navbar_menu.small-screen #menu-button::after {
	border-color: #4f4e50;
}

.full_slider_inner {
	background: #f8f8f8;
}

.header_top {
	background: #f3f3f3;
}

.dark_bg {
	background-color: #4f4e50;
}

.dark_bg_light {
	background-color: #3e505a;
}

a.btn.main_bt:hover,
a.btn.main_bt:focus,
button.btn.main_bt:hover,
button.btn.main_bt:focus {
	background: #f04d24;
	color: #fff;
}

.header_top ul li a:hover,
.header_top ul li a:focus,
.header_top ul li a:active {
	color: #e1192f;
}

.tp-bannertimer {
	background: #e1192f !important;
}

a.btn.dark_gray_bt:hover,
a.btn.dark_gray_bt:focus {
	color: #fff;
}

a.btn.light_btn,
button.btn.light_btn {
	background: #a864a8;
}

a.btn.light_btn:hover,
a.btn.light_btn:focus button.btn.light_btn:hover,
a.btn.light_btn:focus {
	background: #fff;
	color: #632e62;
	border-color: #fff;
}

hr.dottad_border,
.tags a:hover,
.tags a:focus,
.comment_section,
.boder_dottat_small {
	border-color: #632e62;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	border-color: transparent;
}

.theme_color,
.social_icon_style_2 li a,
.blog_section p.comm_head,
.blog_section p.comm_head a.rply,
.description_case_studies_style_2 span i,
.price_table_active .price_amount,
.post_head a:hover,
.post_head a:focus,
a.read_more,
.icon_bottom,
ul.list_style_1 i,
.categary a:hover,
.categary a:focus,
.post_info li i,
.comment_cantrol span,
.footer_top ul.footer_menu li a:hover,
.footer_top ul.footer_menu li a:focus,
footer .social_icon ul li a:hover,
footer .social_icon ul li a:focus,
a.btn:hover,
button.btn:hover,
a.btn:focus,
button.btn:focus {
	color: #e1192f;
}

.price_table_active a.btn:hover,
.price_table_active a.btn:focus {
	background: #252525;
	color: #fff;
}

.border_dotted_circle:hover,
.border_dotted_circle:focus {
	border-color: #632e62;
}

.btn.light_theme_bt {
	background: #a864a8;
}

.btn.light_theme_bt:hover,
.btn.light_theme_bt:focus {
	background: #50254f;
	color: #fff;
	border: solid #50254f 1px;
}

.theme_color_bg .border_dotted_circle,
.theme_color_bg .border_dotted_circle:hover,
.theme_color_bg .border_dotted_circle:focus {
	border-color: #fff;
}

button.border_btn_white_border,
a.border_btn_white_border {
	background: transparent;
	border: solid #fff 1px;
}

button.border_btn_white_border:focus,
button.border_btn_white_border:hover,
a.border_btn_white_border:hover,
a.border_btn_white_border:focus {
	background: #fff;
	border: solid #fff 1px;
}

body.light_blue button.btn.white_btn:hover,
body.light_blue a.btn.white_btn:hover,
body.light_blue button.btn.white_btn:focus,
body.light_blue a.btn.white_btn:focus {
	background: #632e62;
	border-color: #632e62;
	color: #fff;
}

.footer_bg_color {
	background-color: #333333;
	border-bottom: solid rgba(255, 255, 255, .1) 1px;
}

.member_heading {
	border-color: #e1192f;
}

